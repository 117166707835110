import React, { useState, useContext } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

import { Formik, Form } from "formik"
import Reaptcha from "reaptcha"

import AuthContext from "../../context/auth/auth-context"
import CartContext from "../../context/cart/cart-context"

import BlurLoader from "../reusable-components/loaders/blur-loader"
import LinkWrapper from "../reusable-components/link-wrapper"
import SubmitButton from "../reusable-components/buttons/submit-button"

import AccountsFormInputField from "./accounts-form-input-field"
import Error from "./error"
import ShowOnButton from "./show-on-button"

import { pixelCompleteRegistration } from "../../services/facebook-pixel"
import { analyticsRegister } from "../../services/google-analytics"

// ================
// 	  COMPONENT
// ================

const Register = () => {
  const { performRegister, loggedInState } = useContext(AuthContext)
  const { getCart } = useContext(CartContext)

  const [loading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [apiError, setApiError] = useState(false)
  const [captchaPassed, setCaptchaPassed] = useState(
    process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY || false
  )

  // This callback will only run if register was successful
  const registerCallback = () => {
    pixelCompleteRegistration()
    analyticsRegister()
    getCart()
    navigate(`/my-account/`, {
      replace: true,
      state: { showNotice: "Your account has been registered" },
    })
  }

  // Handle a register submission click
  const submitCallback = async (values) => {
    if (!captchaPassed) {
      return setApiError("Please click the Captcha checkbox before continuing.")
    } else {
      setApiError("")
    }

    const { email, password, first_name, last_name } = values
    performRegister(
      email,
      password,
      first_name,
      last_name,
      null,
      setLoading,
      setApiError,
      registerCallback
    )
  }

  return (
    <RegisterPanelContainer>
      <BlurLoader loading={loading || loggedInState === null} message="Working">
        <RegisterFormSectionStyling>
          <h2>Register</h2>
          {apiError && <Error error={apiError.content} />}
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              password: "",
              passwordConfirm: "",
            }}
            validate={validate}
            onSubmit={submitCallback}
          >
            {({ isSubmitting, errors, touched }) => (
              <>
                <Form>
                  <AccountsFormInputField
                    label="First Name"
                    type="text"
                    name="first_name"
                    autoComplete="given-name"
                    placeholder="Jane"
                    errors={errors}
                    touched={touched}
                  />
                  <AccountsFormInputField
                    label="Last Name"
                    type="text"
                    name="last_name"
                    autoComplete="family-name"
                    placeholder="Doe"
                    errors={errors}
                    touched={touched}
                  />
                  <AccountsFormInputField
                    label="Email"
                    type="email"
                    name="email"
                    autoComplete="email"
                    placeholder="jane@doe.com"
                    errors={errors}
                    touched={touched}
                  />
                  <AccountsFormInputField
                    label="Password:"
                    type={hidePassword ? "password" : "text"}
                    name="password"
                    autoComplete="new-password"
                    toggle={() => {
                      setHidePassword((prevState) => !prevState)
                    }}
                    errors={errors}
                    touched={touched}
                    hidePassword={hidePassword}
                  />
                  <AccountsFormInputField
                    label="Confirm Password:"
                    type={hidePassword ? "password" : "text"}
                    name="passwordConfirm"
                    autoComplete="new-password"
                    toggle={() => {
                      setHidePassword((prevState) => !prevState)
                    }}
                    errors={errors}
                    touched={touched}
                    hidePassword={hidePassword}
                  />

                  <RecaptchaContainer>
                    <Reaptcha
                      sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}
                      onVerify={() => {
                        setCaptchaPassed(true)
                      }}
                      size="normal"
                    />
                  </RecaptchaContainer>
                  <StyledButton>
                    <SubmitButton text="REGISTER" disabled={loading} />
                  </StyledButton>
                </Form>
                <Extra>
                  Back to <LinkWrapper to="/login/">Login</LinkWrapper>
                </Extra>
              </>
            )}
          </Formik>
          <ShowOnButton />
        </RegisterFormSectionStyling>
      </BlurLoader>
    </RegisterPanelContainer>
  )
}

// ===============
//     STYLES
// ===============
const RegisterPanelContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  .show-on {
    margin-top: 10px;
  }
`
const RegisterFormSectionStyling = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  .half-width-input input {
    display: block;
  }
  label {
    width: 100%;
    display: inline-block;
    margin-top: 10px;
  }
  .half-width-input {
    max-width: 400px;
    width: 100%;
    display: inline-block;
  }
  & form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .half-width-input {
      width: 48%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .half-width-input {
      width: 100%;
    }
    & form > div {
      width: 100%;
    }
    & form > div button {
      width: 100%;
    }
  }
`
const RecaptchaContainer = styled.div`
  .g-recaptcha {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    max-width: 270px;
    .g-recaptcha {
      justify-content: flex-start;
    }
  }
`
const StyledButton = styled.div`
  margin: 20px 0;
  width: 100%;
  button {
    width: 100%;
  }
`
const Extra = styled.div`
  color: ${({ theme }) => theme.colors.black1};
  text-align: center;
  margin: 20px 0;
  a {
    color: ${({ theme }) => theme.colors.black1};
    text-decoration: underline;
  }
`

// ===============
// 	   HELPERS
// ===============

const validate = ({
  first_name,
  last_name,
  email,
  password,
  passwordConfirm,
}) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!first_name, "first_name", "First name is required")
  addIf(!last_name, "last_name", "Last name is required")
  addIf(!email, "email", "Email is required")
  addIf(!/\S+@\S+\.\S+/.test(email), "email", "Email is invalid")
  addIf(!password, "password", "Password is required")
  addIf(!passwordConfirm, "passwordConfirm", "Enter your password again")
  addIf(
    password !== passwordConfirm,
    "passwordConfirm",
    "Passwords do not match"
  )
  addIf(
    password.length < 6,
    "password",
    "Password should be at least 6 characters"
  )

  return errors
}

export default Register
