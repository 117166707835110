import React from "react"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/header/seo"
import Register from "../components/account/register-panel"

const RegisterPage = ({ location }) => {
  const title = "Register"

  return (
    <Layout location={location}>
      <SEO title={title} location={location} yoast={{}} />
      <Register location={location} />
    </Layout>
  )
}

export default RegisterPage
